import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, Chip, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import SuspenseLoader from 'src/components/SuspenseLoader';
import { useUserDetails } from 'src/hooks/user/useUserDetails';
import { UserSchema, UserSchemaType } from 'src/schemas/userSchema';
import { logger, userService } from 'src/services';
import { parseUserCreateRequest } from 'src/transformer';
import { IModals } from 'src/types';
import { IRole } from 'src/types/acl';
import _without from "lodash/without";
import CancelIcon from '@mui/icons-material/Cancel';
import { notifyError, notifySuccess } from 'src/generic-ui';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IRoleRequest {
    role: string;
    isDeleted?: boolean;
}

interface UserCreateAndUpdateInitialValues {
    firstName: string;
    lastName: string;
    point: string;
    password?: string;
    email?: string;
    roles: IRoleRequest[];
    rolesForUpdate: IRoleRequest[];
}

interface IBasicInfo {
    selectedId?: string;
    config: any;
}

export default function BasicInfo({ selectedId, config }: IBasicInfo ) {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const { isLoading, data, mutate, error, roles } = useUserDetails(selectedId);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [selectedRolesForUpdate, setSelectedRolesForUpdate] = useState<any>([]);
    const { onRefresh, onClose } = config;

    useEffect(() => {
        const selectedRoles = selectedId ? data?.roles?.filter((role: IRole) => role.isAlreadyAdded === true && role.title !== "Customer") : [];
        setSelectedOptions(selectedRoles)
        setSelectedRolesForUpdate(selectedRoles)
    }, [data])

    const initialValues: UserCreateAndUpdateInitialValues = {
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        point: data?.point || "",
        email: data?.email || "",
        roles: [],
        rolesForUpdate: []
    }
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
        setFieldValue
    } = useFormik({
        initialValues: initialValues,
        validationSchema: UserSchema,
        enableReinitialize: true,
        onSubmit(values) {
            const onSave = !!selectedId ? onEditSubmit : onSaveSubmit;
            onSave(values);
        },
    });

    useEffect(() => {
        setFieldValue('roles', selectedOptions)
    }, [setFieldValue, selectedOptions])

    useEffect(() => {
        setFieldValue('rolesForUpdate', selectedRolesForUpdate)
    }, [setFieldValue, selectedRolesForUpdate])

    if (isLoading) {
        return <SuspenseLoader />;
    }

    const onSaveSubmit = async (values: UserSchemaType) => {
        try {
            const requestBody = parseUserCreateRequest(values);
            setSubmitting(true);
            await userService.createUser({ ...requestBody });
            onRefresh?.();
            notifySuccess("User created successfully")
            onClose();
        } catch (error) {
            logger.error(error);
            notifyError(error.message);

        } finally {
            setSubmitting(false);
        }
    };

    const onEditSubmit = async (values: UserSchemaType) => {
        try {
            const requestBody = parseUserCreateRequest(values, "update");
            setSubmitting(true);
            await userService.updateUser(selectedId, { ...requestBody });
            onRefresh?.();
            notifySuccess("User info updated successfully");
        } catch (error) {
            logger.error(error);
            notifyError(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    const modifiedRoles = roles?.filter((role: IRole) => role.isActive && role.title !== "Customer" && role.title !== "Super Admin");

    const handleOptionSelect = (event: SelectChangeEvent<typeof selectedOptions>) => {
        const { value }: any = event.target;
        setSelectedOptions(value);
        setSelectedRolesForUpdate(value)
    };

    const handleDelete = (e: React.MouseEvent, value: any) => {
        e.preventDefault();
        setSelectedOptions((current: any) => _without(current, value));
        const data = selectedRolesForUpdate.map((role) => {
            if (role.id === value.id) {
                return {
                    ...role,
                    isDeleted: true
                }
            }
            return role;
        })
        setSelectedRolesForUpdate(data)
    };

    return (
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="first name"
                            name="firstName"
                            type="text"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {formikError.firstName && touched.firstName ? (
                            <p className="text-red-600">{formikError.firstName}</p>
                        ) : null}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="last name"
                            name="lastName"
                            type="text"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {formikError.lastName && touched.lastName ? (
                            <p className="text-red-600">{formikError.lastName}</p>
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="customer points"
                            name="point"
                            type="text"
                            value={values.point}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {formikError.point && touched.point ? (
                            <p className="text-red-600">{formikError.point}</p>
                        ) : null}
                    </Grid>
                    {!selectedId ?
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="email address"
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {formikError.email && touched.email ? (
                                <p className="text-red-600">{formikError.email}</p>
                            ) : null}
                        </Grid> : null
                    }

                    {!selectedId ? <Grid item xs={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="password"
                            name="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {formikError.password && touched.password ? (
                            <p className="text-red-600">{formikError.password}</p>
                        ) : null}
                    </Grid> : null
                    }

                    <Grid item xs={12}>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={selectedOptions && selectedOptions?.length > 0 ? selectedOptions : []}
                            onChange={handleOptionSelect}
                            label="Role"
                            className="w-full mt-4"
                            placeholder="select roles"
                            input={<OutlinedInput id="select-multiple-chip" label={"Role"} />}
                            renderValue={(selected) => {
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value.id} label={value.title}
                                                deleteIcon={
                                                    <CancelIcon
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }
                                                onDelete={(e) => handleDelete(e, value)}
                                            />
                                        ))}
                                    </Box>
                                )
                            }}
                            MenuProps={MenuProps}
                        >
                            {modifiedRoles?.map((el: any) => {
                                return (
                                    <MenuItem key={el.id} value={el}>
                                        <Checkbox checked={selectedOptions && selectedOptions.find(selected => selected.id === el.id)} />
                                        {el.title}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        {formikError.roles && touched.roles ? (
                            <p className="text-red-600">{formikError.roles}</p>
                        ) : null}
                    </Grid>
                </Grid>
                
                <LoadingButton

                    loadingIndicator="Loading..."
                    type="submit"
                    loading={isSubmitting}
                    color="primary"
                    className={"bg-indigo-600 hover:bg-indigo-800 mt-4 text-white"}
                >
                    Submit
                </LoadingButton>
            </form>
    )
}
